import { MouseEvent, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import HorizontalLayout from "../common/HorizontalLayout";
import VerticalLayout, { IVerticalSplitterState } from "../common/VerticalLayout";
import styles from "./ContentSlides.module.css";
import useApiState, { api, IApiState } from "../../hooks/use-api-state";
import Loading from "../common/Loading";
import { IAppInfo } from "../../messages/IAppInfo";
import { IContentData, IModule, ISlide } from "../../messages/IGetUserContentResponse";
import { NavLink } from "react-router-dom";
import TinyProgressBar from "../common/TinyProgressBar";


const STOP_AFTER_INACTIVE_MINUTES = 15;

export default function ContentSlides(props: {
  appInfo: IAppInfo,
  userId: string,
  courseData: IContentData,
  cohortCode: string,
  lmoduleCode: string,
  vms: string | string[],
  modulePath: string,
  slides: { [slideIndex: string]: ISlide },
  module: IModule,
  previousLessonLink: string | null,
  nextLessonLink: string | null,
  contentStructureId: string,
  chapterId: string,
  onSlideVisited: (slideId: string) => void,
}): ReactElement {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [apiState, setApiState] = useApiState(true);

  const [notesShown, setNotesShown] = useState<boolean>(false);
  const [labId, setLabId] = useState<string | null>(null);
  const [labStatus, setLabStatus] = useState<string>("");
  //const [showProgressbar, setShowProgressbar] = useState<boolean>(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [lessonButtonsMargin, setLessonButtonsMargin] = useState<number>(0);
  const [notesToggleButtonsMargin, setNotesToggleButtonsMargin] = useState<number>(0);
  const [progressbarPercentage, setProgressbarPercentage] = useState<number|null>(null);

  var lastImageId = useRef<string | null>(null);

  useEffect(() => {
    labcontrol_init(props.appInfo.gcs_address, props.userId, props.appInfo.csrfToken);
  }, [props.appInfo, props.userId]);


  useEffect(() => {
    setCurrentSlideIndex(0);
    setLabId(null);
  }, [props.cohortCode, props.modulePath]);


  function repositionChangeLessonButtons() {
    var iframe_container = document.getElementById("iframe_container");
    var slide_container = iframeRef.current?.contentDocument?.getElementsByClassName("main-container")[0];
    if (iframe_container && slide_container) {
      var padding = (iframe_container?.clientWidth-slide_container?.clientWidth)/2;
      /*if (padding>250)
        padding = 250;*/
      setLessonButtonsMargin(padding);
      setNotesToggleButtonsMargin(padding);
    }
  }


  function resizeIframe(heightPx: number) {
    if (!!iframeRef.current) {
      // var SLIDE_INNER_WIDTH_PER_HEIGHT_RATIO = 860.0 / 547.0;
      const iframeHeightPx = Math.max(0, heightPx - 15);
      iframeRef.current.height = `${iframeHeightPx}px`;
    }
  }

  function handleSplitterChange(state: IVerticalSplitterState) {
    resizeIframe(state.topPanelHeightPx);
    repositionChangeLessonButtons();
  }

  const handleSlideChanged = useCallback(async (slideIndex: number) => {
    setCurrentSlideIndex(slideIndex);
    setProgressbarPercentage(null);

    if (!!props.module.labs) {
      let labs = props.module.labs[slideIndex.toString()] ?? null;
      if (labs !== null && typeof labs === 'string' && labs !== "") {
        setLabId(labs);
        setLabStatus("");
      } else {
        setLabId(null);
      }
    }

    const slide = props.slides[slideIndex.toString()];
    const slideCount = Object.keys(props.slides).length;

    let slideId = slide.id;
    if (slideIndex === slideCount - 1) {
      slideId = "_END";
    }

    //For the first slide, enforce the slide_id to be _START
    if (slideIndex === 0 && !slideId) {
      slideId = "_START";
    }

    setNotesShown(false);
    props.onSlideVisited(slideId);
  }, [props]);


  const handleMessage = useCallback(async (e: any) => {
    if (e.data.command === "slidechanged") {
      await handleSlideChanged(+e.data.slideindex);
    }
  }, [handleSlideChanged]);


  useEffect(() => {
    window.addEventListener("message", handleMessage);
    const resizeObserver = new ResizeObserver(() => {
      repositionChangeLessonButtons();
    });
    if (iframeRef.current)
      resizeObserver.observe(iframeRef.current);

    repositionChangeLessonButtons();
    return () => {
        window.removeEventListener("message", handleMessage);
        resizeObserver.disconnect();
      };
    }, [handleMessage]);


  function continuouslyPollVM(vm_imageId:string) {
    pollVmState(vm_imageId,
      (imageId,vm_data) => {
        //console.log(`POLL ${JSON.stringify(vm_data)}`);
        setApiState((state: IApiState) => { return { ...state, isLoading: false, }; });
        const progress = vm_data.progress;
        if (progress && progress.status) {
          var status = progress.status ?? "";
          if (progress.proc) {
            setProgressbarPercentage(parseInt(progress.proc??"0"));
          } else {
            setProgressbarPercentage(null);
            //if (status==="stopping") // Cheating: stopping w/o percentage means that the VM is being shut down automatically due to inactivity 
            //  status = "auto_stopping";
          }
          setLabStatus(status);
        } else {
          var state = vm_data.state ?? "";
          //if (state==="stopping") // Cheating: stopping in state (and not in progress) means that the VM is being shut down automatically due to inactivity 
          //  state = "auto_stopping";
          setLabStatus(state);
          setProgressbarPercentage(null);
        }
      },
      (message, log) => {
        console.log(`ERROR OCCURRED: ${message}`);
        console.log(`-${log}`);
      }
    );
  }

  useEffect(() => {
    let imageId = null;
    if (!!props.vms) {
      imageId = getVmImageId(props.vms);
    }
    if (labId && imageId) {
      lastImageId.current = imageId;
      continuouslyPollVM(imageId);
    }
    else
    {
      if (lastImageId.current)
        stopLabTimer(lastImageId.current);
      setApiState((state: IApiState) => { return { ...state, isLoading: false, }; });
      return;
    }
  }, [labId, props.vms]);

  
  async function startLabHandler(e: MouseEvent<HTMLButtonElement>, labId: string) {
    e.preventDefault();
    const vm_imageId = getVmImageId(props.vms);
    startLab(vm_imageId, props.cohortCode, props.lmoduleCode, labId, 
    (status_type, imageId, data) => {
      //console.log(`STARTLAB (${status_type}): ${JSON.stringify(data)}`);
      if (status_type==="init") {
        //console.log(`startLab init: ${JSON.stringify(data)}`);
        setProgressbarPercentage(0);
        setLabStatus("starting"); // It can't be anything else here, just "starting"
      }
      else if (status_type==="progress") {
        //console.log(`startLab progress: ${JSON.stringify(data)}`);
        setProgressbarPercentage(parseInt(data.proc??"0"));
        if (data.status) {
          var status = data.status ?? "";
          //Bit of cheating here
          if (status==="stopped") { // "stopped" state may occur at the beginning of the bootup process, so we change it to "starting"
            status = "starting";
          }
          else if (status==="no_instance") { // "no_instance" state may occur at the beginning of the creation process, so we change it to "creating"
            status = "creating";
          }
          setLabStatus(status);
        }
      }
      else if (status_type==="done") {
        //console.log(`startLab done: ${JSON.stringify(data)}`);
        if (data.status) {
          setLabStatus(data.status??"");
        }
        setProgressbarPercentage(null);
        continuouslyPollVM(imageId);
      }
    },
    (message, log) => {
      console.log(`ERROR OCCURRED: ${message}`);
      console.log(`-${log}`);
    }
    );
  }

  const previousLessonVisibilityHiddenClass = !!props.previousLessonLink && currentSlideIndex === 0 ? "" : styles.visibility__hidden;
  const nextLessonVisibilityHiddenClass = !!props.nextLessonLink && currentSlideIndex === Object.keys(props.slides).length - 1 ? "" : styles.visibility__hidden;


  function isNotesShown() {
    return !!notesShown;
  }

  function toggleNotes() {
    setNotesShown((prev: boolean) => !prev);
  }

  function getNotesClass() {
    return `${styles.notes__container} ${isNotesShown() ? "shown" : "hidden"}`
  }

  function getNotesButtonClass() {
    var shownClass = `${styles.notes_toggle_button} ${isNotesShown() ? "shown" : "hidden"}`;
    return shownClass;
  }

  function GetImageNameFromStatus(status: string) {
    const active = isVMActive(status);
    switch(active) {
      case "Y": // Active
        return "lab_B_40x40_2.png"; // Green
      case "N": // Inactive
        return "lab_B_40x40_inactive.png"; // Grey
      case "A": // Activating
        return "lab_B_40x40.png"; // Blue
      default: // Unknown / deactivating
        return "lab_B_40x40_inactive.png"; // Grey
    }
  }

  function GetImageHoverFromStatus(status: string) {
    const active = isVMActive(status);
    switch(active) {
      case "X": // Not available
      return "The VM is unavailable. This is a configuration issue. Please contact support!";
      case "Y": // Active
        return `The VM is running. It will be stopped after ${STOP_AFTER_INACTIVE_MINUTES} minutes of inactivity.`;
      case "N": // Inactive
        return "The VM is stopped. Please boot it up do some labs!";
      case "A": // Activating
        return "The VM is starting.";
      default: // Unknown / deactivating
        return "The VM is stopping.";
    }
  }

  //sqsutils_init(props.appInfo.???, props.appInfo.clientId, props.appInfo.csrfToken);

  return <>
    {currentSlideIndex < Object.keys(props.slides).length &&
      <HorizontalLayout
        keyForStorage="content_slides"
        minimizedTitle="Slides"
        collapsiblePanel="right"
        className={styles.content__container}
        leftPanelClassName="slide__container"
        togglerClassName={props.courseData.content_structure.no_narration ? "" : styles.narration_toggle_button}
        leftContent={
          <VerticalLayout
            keyForStorage="contentSlides"
            onSplitterChange={handleSplitterChange}
            topPanel={
              <>
                <div id="iframe_container" className={styles.iframe__container}>
                  <iframe
                    title="Slides"
                    ref={iframeRef}
                    src={`${props.modulePath}?resume=0`}
                    className={styles.content__iframe}
                    width="100%"
                  />
                  <div className={styles.change_lesson_controls}>
                    <button className={`btn narrow primary ${styles.change_lesson_button} ${previousLessonVisibilityHiddenClass}`}
                            style={{ marginLeft: lessonButtonsMargin }}>
                      <NavLink to={props.previousLessonLink ?? ""}>Previous lesson</NavLink>
                    </button>
                    <button className={`btn narrow primary ${styles.change_lesson_button} ${nextLessonVisibilityHiddenClass}`}
                            style={{ marginRight: lessonButtonsMargin }}>
                      <NavLink to={props.nextLessonLink ?? ""}>Next lesson</NavLink>
                    </button>
                    {!!props.slides[currentSlideIndex].notes &&
                    <button className={`btn narrow ${getNotesButtonClass()}`}
                      style={{ marginRight: notesToggleButtonsMargin }}
                      onClick={toggleNotes}
                    />
                    }
                  </div>
                </div>
              </>
            }
            bottomPanel={
              <>
                { !!labId &&
                  <Loading isLoading={apiState.isLoading}>                
                    <div className={styles.lab_panel}>
                      {/*Status: {labStatus} ({progressbarPercentage}%) {progressbarPercentage===null}<br/>*/}
                      <div className={styles.lab_image}>
                        <img
                          src={`/icons/${GetImageNameFromStatus(labStatus)}`}
                          title={GetImageHoverFromStatus(labStatus)}
                          alt=""
                        />
                      </div>
                      { !!labStatus &&
                        <div className={styles.start_lab_panel}>
                          { progressbarPercentage===null ?
                            <>
                            { /* This is a kind of switch, several conditional elements */
                              (isVMActive(labStatus)==="X" && /* "X" is a special case, it means that the VM image is not available */
                              <p className={styles.start_lab_text}>There is no VM for this lab. This is a configuration issue. Please contact support!</p>
                              )
                            || (labStatus==="auto_stopping" && /* Auto stopping => show a specific message. */
                                <p className={styles.start_lab_text}>The VM is being stopped due to inactivity; you cannot start it right now.</p>
                              )
                            || (isVMActive(labStatus)==="D" && /* All other deactivating states (stopping, terminating, shutting-down, etc.), cannot start for the moment. */
                                <p className={styles.start_lab_text}>You cannot start this VM right now.</p>
                              )
                            || (isVMActive(labStatus)!=="D" /* "Y", "N" or possible "A" (but in this latter case it's most probably showProgressbar=true) */ &&
                                <p className={styles.start_lab_text}>
                                {isVMActive(labStatus)==="Y" ? `VM is running. ` : ""}
                                Click <button onClick={(e) => startLabHandler(e, labId)}
                                >here</button> to start the lab.
                                </p>
                              )
                            }
                            </>
                          :
                            <>
                            { labStatus==="connecting" ?
                              <p className={styles.start_lab_text}>Connecting...</p>
                            :
                              <div className={styles.start_lab_progressbar}>
                                <TinyProgressBar
                                  percentage={progressbarPercentage??0}
                                  status={getStatusText(labStatus)}
                                />
                              </div>
                            }
                            </>
                          }
                        </div>
                      }
                    </div>
                  </Loading>
                }
                {!!props.slides[currentSlideIndex].notes &&
                  <div className={getNotesClass()}>
                    <div dangerouslySetInnerHTML={{ "__html": props.slides[currentSlideIndex].notes }}></div>
                  </div>
                }
              </>
            }
          />
        }
        rightContent={
          <>
            <div className={styles.narration}>
              {!!props.slides[currentSlideIndex].narration &&
                <div dangerouslySetInnerHTML={{ "__html": props.slides[currentSlideIndex].narration }}></div>
              }
            </div>
          </>
        }
      />
    }
  </>
}
